'use client'
import SellForm from "@components/SellForm"
import { useState } from "react"
import { Dialog } from '@headlessui/react'
import { AiFillCloseSquare } from 'react-icons/ai'
import tailwindConfig from '@tailwind.config'

const ListWithUs = ({ dictionary, lang }) => {
    const [needHelpIsOpen, setNeedHelpIsOpen] = useState(false)

    const togglePopup = () => {
        setNeedHelpIsOpen(true)
    }

  return (
    <div className='w-full bg-maincolor py-4 shadow-md'>
        <div className='md:w-full 2xl:w-8/12 mx-auto flex sm:flex-row flex-col sm:px-0 px-3'>
            <div className="sm:w-1/2 w-full">
                <h3 className='text-2xl text-light'>{dictionary.section.listWithUsTitle}</h3>
                <p className='text-xl text-light'>{dictionary.section.listWithUsDescription}</p>
            </div>
            <div className="sm:w-1/2 w-full  flex items-center justify-center">
                <button onClick={togglePopup} className="sm:w-[200px] w-full sm:mt-0 mt-4 p-2 bg-third text-light font-bold rounded-md">{dictionary.section.listWithUs}</button>
                <Dialog
                        open={needHelpIsOpen}
                        onClose={() => setNeedHelpIsOpen(false)}
                        className="relative z-50"
                        >
                        {/* The backdrop, rendered as a fixed sibling to the panel container */}
                        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                        {/* Full-screen container to center the panel */}
                        <div className="fixed inset-0 overflow-y-auto flex w-screen items-center justify-center">
                            {/* The actual dialog panel  */}
                            <Dialog.Panel className="mx-auto md:w-full lg:w-[600px] rounded bg-white">
                                <div className='w-full flex justify-between items-start pt-4 px-4'>
                                    <div>
                                        <h2 className='text-maincolor font-semibold text-xl mb-1'>{dictionary.sellForm.fillTheForm}</h2>
                                    </div>
                                    <button onClick={() => setNeedHelpIsOpen(false)}>
                                        <AiFillCloseSquare
                                            color={tailwindConfig.theme.extend.colors.maincolor}
                                            size={32}
                                        />
                                    </button>
                                </div>
                                    <div className='w-full h-full py-6 flex md:flex-row flex-col flex-nowrap md:flex-wrap mx-auto px-0'>
                                        {/* <div className='sm:w-1/2 sm:flex hidden rounded-md overflow-hidden'>
                                            <div className='relative w-full h-full p-2'>
                                                <Image
                                                    // placeholder="blur"
                                                    // blurDataURL={property.blur}
                                                    // className='object-fit'
                                                    className='object-cover px-2'
                                                    src={'/images/listwithus.avif'}
                                                    // width={imageWidth}
                                                    // height={imageHeight}
                                                    fill={true}
                                                    alt={'List with Us'}
                                                />
                                            </div>
                                        </div> */}
                                        <div className='sm:w-full w-full bg-light overflow-hidden rounded-md py-2 px-4'>
                                            <SellForm dictionary={dictionary.sellForm} lang={lang} />
                                        </div>
                                    </div>
                            </Dialog.Panel>
                        </div>
                    </Dialog>
            </div>
        </div>
    </div>
  )
}

export default ListWithUs