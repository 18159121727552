'use client'
import { useState, useEffect } from 'react'
import { FaSearch, FaHome, FaBuilding } from 'react-icons/fa'
import { MdSell, MdVilla } from 'react-icons/md'
import { AiFillLayout } from 'react-icons/ai'
import { FaShop } from "react-icons/fa6";
import Link from 'next/link'
import { searchTypes } from '@constants/searchTypes';
import { company } from '@constants/company';
import tailwindConfig from '@tailwind.config'
import { RadioGroup } from '@headlessui/react'
import HeroDropdownSearch from '@components/HeroDropdownSearch'
import HomeMinPriceFilter from '@components/Search/WebFilter/HomeMinPriceFilter'
import HomeMinRentPriceFilter from '@components/Search/WebFilter/HomeMinRentPriceFilter'
import HomeMaxPriceFilter from '@components/Search/WebFilter/HomeMaxPriceFilter'
import HomeMaxRentPriceFilter from '@components/Search/WebFilter/HomeMaxRentPriceFilter'
import HomeRoomFilter from '@components/Search/WebFilter/HomeRoomFilter'
import { Listbox } from '@headlessui/react'
import { FaCheck } from "react-icons/fa";
import { Popover } from '@headlessui/react'
import { categoryTypes } from '@constants/categoryTypes'
import { saleMinPrices, saleMaxPrices } from '@constants'

// const categories = [
//     // { id: 1, name: 'Category' },
//     { id: 2, name: 'Pool Villa', value: 'Poolvilla' },
//     { id: 3, name: 'House', value: 'House' },
//     { id: 4, name: 'Condo', value: 'Condo' },
//     { id: 5, name: 'Land', value: 'Land' },
//     { id: 6, name: 'Commercial', value: 'Commercial' }
//   ]

const defaultCategory = {
    'en': [
    {
        title: 'Category',
        value: 'all'
    }],
    'th': [
    {
        title: 'ประเภท',
        value: 'all'
    }],
    'cn': [
    {
        title: '所有',
        value: 'all'
    }]
}

const defaultBedroom = {
    'en': [
    {
        title: '',
        value: '0'
    }],
    'th': [
    {
        title: '',
        value: '0'
    }],
    'cn': [
    {
        title: '',
        value: '0'
    }]
}


const FrontSearchBarWithInput = ({ searchList, dictionary, lang }) => {
    let [sale, setSale] = useState(searchTypes[lang]?.[0])
    const [inputText, setInputText] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(defaultCategory[lang])
    const [selectedMinPrice, setSelectedMinPrice] = useState('')
    const [bedrooms, setBedrooms] = useState(defaultBedroom[lang][0])
    const [bedroomsQuery, setBedroomsQuery] = useState('')
    const [categoryQuery, setCategoryQuery] = useState('')
    const [minPrice, setMinPrice] = useState(saleMinPrices[lang][0])
    const [minpriceQuery, setMinpriceQuery] = useState('')
    const [maxPrice, setMaxPrice] = useState(saleMaxPrices[lang].at(-1))
    const [maxpriceQuery, setMaxpriceQuery] = useState('')

    const [searchUrl, setSearchUrl] = useState('')

    const handleUpdateParams = (e) => {
        if (e.type === 'name') {
            setSearchUrl(`&name=${e.name}`)
        }
        if (e.type === 'district') {
            setSearchUrl(`&district=${e.id}`)
        }
        if (e.type === 'location') {
            setSearchUrl(`&location=${e.name}`)
        }
        if (e.type === 'newdevelopment') {
            setSearchUrl(`/new-developments/${e.slug}`)
        }
        if (e.type === 'project') {
            setSearchUrl(`/projects/${e.slug}`)
        }
    }
    const handleSaleTypeParams = (e) => {
        setMinPrice({ value: '0', nom: 'Any' })
        setMaxPrice({ value: '', nom: 'Any' })
        setMinpriceQuery('')
        setMaxpriceQuery('')
    }

    const handleBedroomUpdate = (e) => {
        // console.log(e)
        setBedrooms(e)
        if (e.value !== '0') {
            setBedroomsQuery(`&room=${e.value}`)
        } else {
            setBedroomsQuery('')
        }
    }

    const toggleCategories = (e) => {
        // console.log(e)
        let newCategoryArray = []
        let newCategoryQueryArray = []
        if (e.length > 0) {
            e.forEach(element => {
                if (element.value !== 'all') {
                    newCategoryArray.push(element)
                    newCategoryQueryArray.push(element.value)
                }
            });
        } else {
            newCategoryArray.push(defaultCategory[lang][0])
            newCategoryQueryArray = []
        }

        setSelectedCategory(newCategoryArray)
        // console.log(newCategoryQueryArray)
        const newCategoryString = newCategoryQueryArray.toString().toLowerCase().replaceAll(',', '%2C')
        if (newCategoryQueryArray.length > 0) {
            setCategoryQuery(`&categories=${newCategoryString}`)
        } else {
            setCategoryQuery('')
        }
    }
    const minPriceQuery = (e) => {
        // console.log(e)
        if (e.value !== '0') {
            setMinpriceQuery(`&minprice=${e.value}`)
            setMinPrice(e)
        } else {
            setMinpriceQuery('')
            setMinPrice('')
        }
    }
    const maxPriceQuery = (e) => {
        // console.log(e)
        if (e.value !== '') {
            setMaxpriceQuery(`&maxprice=${e.value}`)
            setMaxPrice(e)
        } else {
            setMaxpriceQuery('')
            setMaxPrice('')
        }
    }

    // useEffect(() => {
    //     console.log('set sale')
    //     const resetPrices = () => {
    //         setMinPrice({ value: '0', nom: 'Any' })
    //         setMaxPrice({ value: '', nom: 'Any' })
    //         setMinpriceQuery('')
    //         setMaxpriceQuery('')
    //     }
    //     resetPrices()
    // }, [setSale])
    

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <div className='mb-5'>
                <h1 className='text-light md:text-[60px] text-xl font-bold'>{company.heroTitle}</h1>
            </div>
            {/* <div className="block md:hidden mt-3">
                <div className='flex flex-col w-[320px] bg-light rounded-md pt-1 shadow-[0_3px_10px_rgb(0,0,0,0.2)]'>
                    <div className='w-full flex justify-center border-forth border-b-[1px]'>
                        <h1 className='text-maincolor font-semibold'>What are you looking for?</h1>
                    </div>
                    <div className="w-full">
                        <div className="w-full">
                            <RadioGroup value={sale} onChange={(value) => {
                                setSale(value)
                                // handleUpdateParams(value)
                            }}
                                className='w-full py-1 px-2 mt-1 flex flex-row'
                            >
                                {searchTypes[lang].map((saleType) => (
                                    <RadioGroup.Option
                                        key={saleType.value}
                                        value={saleType}
                                        checked={saleType === sale}
                                        className={({ active, checked }) =>
                                            `${active
                                                ? ''
                                                : ''
                                            }
                                    ${checked ? 'bg-maincolor text-light' : 'bg-light'
                                            }
                                    w-full relative flex cursor-pointer rounded-lg px-2 py-2 shadow-md focus:outline-none`
                                        }
                                    >
                                        {({ active, checked }) => (
                                            <>
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-sm text-center">
                                                            <RadioGroup.Label
                                                                as="p"
                                                                className={`font-medium w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                    }`}
                                                            >
                                                                {saleType.title}
                                                            </RadioGroup.Label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                    <div className='flex flex-row w-full justify-between'>
                        <Link href={`/properties?saleType=${sale.value}&category=house&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><MdVilla size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs'>Villa</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=land&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><AiFillLayout className='hover:secondary' size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs'>Land</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=condo&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><FaBuilding size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs'>Condo</span></Link>
                        <Link href={`/properties?saleType=${sale.value}&category=commercial&filtertab=true`} className='flex flex-col cursor-pointer justify-center items-center w-24 h-20 hover:font-bold hover:drop-shadow-md rounded-lg'><FaShop size={32} color={tailwindConfig.theme.extend.colors.maincolor} /><span className='text-maincolor text-xs'>Commercial</span></Link>
                    </div>
                </div>
            </div> */}
            <div className="block">
                <div className='flex flex-col md:w-[680px] w-[360px] rounded-md'>

                    <div className="w-full">
                        <div className="md:w-1/2 w-full bg-white bg-opacity-70 rounded-md overflow-hidden">
                            <RadioGroup value={sale} onChange={(value) => {
                                setSale(value)
                                handleSaleTypeParams(value)
                            }}
                                className='w-full flex flex-row'
                            >
                                {searchTypes[lang].map((saleType) => (
                                    <RadioGroup.Option
                                        key={saleType.value}
                                        value={saleType}
                                        checked={saleType === sale}
                                        className={({ active, checked }) =>
                                            `${active
                                                ? ''
                                                : ''
                                            }
                                    ${checked ? 'bg-maincolor hover:bg-secondary text-light' : 'bg-light hover:bg-slate-200'
                                            }
                                    w-full relative flex cursor-pointer  p-2 shadow-md focus:outline-none`
                                        }
                                    >
                                        {({ active, checked }) => (
                                            <>
                                                <div className="flex w-full items-center justify-between">
                                                    <div className="flex items-center">
                                                        <div className="text-xl text-center">
                                                            <RadioGroup.Label
                                                                as="p"
                                                                className={`font-xl w-full  ${checked ? 'text-light' : 'text-maincolor'
                                                                    }`}
                                                            >
                                                                {saleType.title}
                                                            </RadioGroup.Label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                        </div>
                    </div>
                    <div className='flex flex-row items-center my-4 relative'>
                        <HeroDropdownSearch title='bts' onChange={handleUpdateParams} searchList={searchList} dictionary={dictionary} />
                        <div className='bg-white rounded-r-lg'>
                            <Link className='bg-third hover:bg-maincolor hover:shadow-md transition-colors duration-300 text-light md:py-4 py-2 pl-2 rounded-r-md inset-y-0 right-0 top-0 bottom-0 text-xl flex items-center pr-2' href={`${(!searchUrl.includes('new-development') && !searchUrl.includes('projects')) ? `/properties?saleType=${sale.value}` : ''}${searchUrl}${bedroomsQuery}${categoryQuery}${minpriceQuery}${maxpriceQuery}`}>{dictionary.hero.search}</Link>
                        </div>
                    </div>
                    <div className='relative flex flex-row w-full justify-start items-center md:flex-nowrap flex-wrap'>
                        <div className='mr-2 max-w-1/2'>
                            <Listbox value={selectedCategory} onChange={toggleCategories} multiple>
                                <Listbox.Button className='text-white text-xl border-[1px] border-light md:p-2 p-1 rounded-md md:max-w-[300px] max-w-[150px] text-nowrap truncate'>
                                    {selectedCategory.map((category) => category.title).join(', ')}
                                </Listbox.Button>
                                <Listbox.Options className='md:w-[200px] w-[360px] z-50 mt-2 bg-light rounded-md p-2 text-maincolor absolute'>
                                    {categoryTypes[lang].map((category) => (
                                        <Listbox.Option
                                            className={`rounded-md p-2 cursor-pointer hover:bg-maincolor hover:text-light`}
                                            key={category.value} 
                                            value={category}
                                         >
                                            {({ active, selected }) => (
                                                <span className={`${selected  ? 'font-bold' : ''} flex flex-row items-center`}>
                                                    { selected ? <FaCheck className='mr-2' /> : '' }
                                                    {category.title}
                                                </span>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Listbox>
                        </div>
                        <div className='mr-2'>
                            <Popover className="md:relative">
                                <Popover.Button className='text-white text-xl border-[1px] border-light md:p-2 p-1 rounded-md'>{dictionary.hero.price}</Popover.Button>

                                <Popover.Panel className="absolute md:w-[200px] w-[360px] left-0 z-10 bg-light p-2 rounded-md mt-2">
                                    {sale.value == 'sale' ? (
                                        <div className='flex flex-col'>
                                            <HomeMinPriceFilter title='minprice' onChange={minPriceQuery} minPrice={minPrice} maxPrice={maxPrice} dictionary={dictionary.filter} lang={lang} />
                                            <HomeMaxPriceFilter title='maxprice' onChange={maxPriceQuery} minPrice={minPrice} maxPrice={maxPrice} dictionary={dictionary.filter} lang={lang} />
                                        </div>
                                    ) : (
                                        <div className='flex flex-col'>
                                            <HomeMinRentPriceFilter title='minprice' onChange={minPriceQuery} minPrice={minPrice} maxPrice={maxPrice} dictionary={dictionary.filter} lang={lang} />
                                            <HomeMaxRentPriceFilter title='maxprice' onChange={maxPriceQuery} minPrice={minPrice} maxPrice={maxPrice} dictionary={dictionary.filter} lang={lang} />
                                        </div>
                                    )}
                                </Popover.Panel>
                            </Popover>
                        </div>
                        <div className=''>
                        <Popover className="md:relative">
                            {lang === 'th' ? (
                                <Popover.Button className='text-white text-xl border-[1px] border-light md:p-2 p-1 rounded-md'>{bedrooms.title == 'สตูดิโอ' ? `สตูดิโอ` : `${bedrooms.title} นอน`}</Popover.Button>

                            ) : (
                                <Popover.Button className='text-white text-xl border-[1px] border-light md:p-2 p-1 rounded-md'>{bedrooms.title == 'Studio' ? `Studio` : `${bedrooms.title} ${dictionary.filter.numberOfBedrooms}${bedrooms.value > 0 ? 's' : ''}`}</Popover.Button>
                            )}
                            <Popover.Panel className="absolute md:w-[320px] w-[360px] left-0 z-10 bg-light p-2 rounded-md mt-2">
                                <HomeRoomFilter onChange={handleBedroomUpdate} title='rooms' dictionary={dictionary.filter} lang={lang} />
                            </Popover.Panel>
                        </Popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontSearchBarWithInput