const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

export const sendSellForm = async (userId = null, name, selectedCountry, phone, email, message, sale, type, contact, line, wechat) => {

    const data = {
        userId,
        name,
        phonePrefix: selectedCountry,
        phone,
        email,
        message,
        sale,
        type,
        preferredContact: contact.value,
        line,
        wechat
    };

    const url = `${baseUrl}/enquiry/sell`

    const headers = {
        'apikey': process.env.NEXT_PUBLIC_RSTBASE_API_KEY,
        'Content-Type': 'application/json'
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        cache: 'no-store',
        body: JSON.stringify(data)
    })

    const result = await response.json()
    return result

}