'use client'
import React from 'react'
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import { company } from '@constants/company';
import Link from 'next/link';
import { FaLine, FaYoutube, FaTiktok  } from 'react-icons/fa'
import { AiFillInstagram, AiFillFacebook, /*AiFillLinkedin, AiFillTwitterSquare,*/ } from 'react-icons/ai'
import tailwindConfig from '@tailwind.config'

const HomeMap = ({ latitude, longitude, dictionary }) => {
  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const center = {
      lat: latitude,
      lng: longitude - 0.02,
  };

  const markerPos = {
      lat: latitude,
      lng: longitude,
  };

  const customMarkerIcon = {
      url: "/images/marker.png",

  };

  const options = {
      disableDefaultUI: true,
      zoomControl: true,
  };

  return (
    <div className='relative mb-3 pb-3 pt-2 md:h-[600px] h-auto'>
      {/* <div className='absolute z-20 bg-maincolor text-light flex flex-col translate-y-[50%]'>
        <h1 className='text-maincolor text-2xl font-bold'>Visit Us</h1>
      </div> */}
      <div className='z-20 sm:absolute relative left-0 right-0 top-0 bottom-0 flex items-center'>
        <div className='md:w-full 2xl:w-8/12 w-full h-full py-6 flex md:flex-row flex-col flex-nowrap md:flex-wrap mx-auto px-0'>
          <div className='md:w-1/2 w-full flex flex-col justify-between bg-maincolor text-light text-xl rounded-md p-6'>
              <h3 className='text-2xl text-light'>{dictionary.contactUs}</h3>
              <p className='font-semibold'>{dictionary.address}</p>
              <p className=''>{dictionary.address1}</p>
              <p className=''>{dictionary.address2}</p>
              <br />
              <p className='font-semibold'>{dictionary.phone}</p>
              <p className=''>{company.phone1}</p>
              <p className=''>{company.phone2}</p>
              <br />
              <p className='font-semibold'>{dictionary.email}</p>
              <p className=''>{company.email}</p>
              <br />
              <div className='flex flex-row justify-between'>
                <Link href={company.lineUrl} className='flex flex-row justify-center bg-gradient-to-r from-[#32CD32] to-[#2dbc2d] py-2 px-2 my-1 items-center rounded-md shadow-sm drop-shadow-md' target="_blank">
                    <FaLine
                        color={tailwindConfig.theme.extend.colors.light}
                        size={30}
                        className=''
                    />
                </Link>
                <Link href={company.facebookUrl} className='flex flex-row justify-center bg-gradient-to-r from-[#1877f2] to-[#0866ff] py-2 px-2 my-1 items-center rounded-md shadow-sm drop-shadow-md' target="_blank">
                    <AiFillFacebook
                        color={tailwindConfig.theme.extend.colors.light}
                        size={30}
                        className=''
                    />
                </Link>
                <Link href={company.instagramUrl} className='flex flex-row justify-center bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 py-2 px-2 my-1 items-center rounded-md shadow-sm drop-shadow-md' target="_blank">
                    <AiFillInstagram
                        color={tailwindConfig.theme.extend.colors.light}
                        size={30}
                        className=''
                    />
                </Link>
                <Link href={company.youtubeUrl} className='flex flex-row justify-center bg-gradient-to-r from-[#FF0000] via-red-500 to-[#FF0000] py-2 px-2 my-1 items-center rounded-md shadow-sm drop-shadow-md' target="_blank">
                    <FaYoutube
                        color={tailwindConfig.theme.extend.colors.light}
                        size={30}
                        className=''
                    />
                </Link>
                <Link href={company.tiktokUrl} className='flex flex-row justify-center bg-gradient-to-r from-[#000000] via-[#000000] to-[#ff0050] py-2 px-2 my-1 items-center rounded-md shadow-sm drop-shadow-md' target="_blank">
                    <FaTiktok 
                        color={tailwindConfig.theme.extend.colors.light}
                        size={30}
                        className=''
                    />
                </Link>
              </div>
          </div>
        </div>
      </div>
      <div className='sm:block hidden'>
        <LoadScript googleMapsApiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15} // Adjust the zoom level as needed
                options={options}
            >
                <Marker position={markerPos} icon={customMarkerIcon} className=' filter-maincolor' scaledSize={20} />
            </GoogleMap>
        </LoadScript>
      </div>
</div>
  )
}

export default HomeMap