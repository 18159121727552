'use client'
import React, { Component} from 'react';
// import { FacebookProvider, Page } from 'react-facebook';
import Script from 'next/script';
// import FacebookProvider from './FacebookProvider';

const TiktokTimeline = async ({ children }) => {
  return (
    <>
      <Script async defer src="https://www.tiktok.com/embed.js" />
      <blockquote cite="https://www.tiktok.com/@propertyspace.th" data-unique-id="propertyspace.th" data-embed-type="creator" className="sm:block hidden tiktok-embed max-w-[600px] min-w-[280px] h-[470px]" > <section> <a target="_blank" href="https://www.tiktok.com/@propertyspace.th?refer=creator_embed">@propertyspace.th</a> </section> </blockquote>
      {/* <blockquote class="tiktok-embed" cite="https://www.tiktok.com/@pattayapropertyspace" data-unique-id="pattayapropertyspace" data-embed-type="creator" className="block sm:hidden max-w-[600px] min-w-[350px] h-[600px]" > <section> <a target="_blank" href="https://www.tiktok.com/@pattayapropertyspace?refer=creator_embed">@pattayapropertyspace</a> </section> </blockquote> */}
      
      {/* <div className='sm:block hidden'>
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FpropertySpace.Th&tabs=timeline&width=600&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=309763885743805" width="600" height="380" className=''  scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div>
      <div className='block sm:hidden'>
        <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FpropertySpace.Th&tabs=timeline&width=380&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=309763885743805" width="380" height="400" className=''  scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
      </div> */}
      {/* <div class="fb-page" data-href="https://www.facebook.com/propertySpace.Th" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/propertySpace.Th" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/propertySpace.Th">Facebook</a></blockquote></div> */}
    </>
  )
}

export default TiktokTimeline